body,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p,
.navbar,
.brand,
.btn-simple,
.alert,
a,
.td-name,
td,
button.close {
  font-family: "Sarabun", sans-serif !important;
}
button {
  font-family: "Sarabun", sans-serif !important;
}
.radio {
  .form-check-input {
    // margin-top: 8px;
  }
  .form-check-input:checked {
    background-color: #fff;
    background-image: url("../img/Ellipse24.svg");
    background-size: 80%;
    background-position: center;
  }
}
.radio .text-info {
  color: #0079e8 !important;
}
.text-caption {
  font-size: 16px;
  color: #999999;
}
.text-primary2,
.text-primary2:hover {
  color: #2d6cb5 !important;
}
.ant-menu-title-content{
  font-weight: 500;
  color:rgb(45, 108, 181)
}

.attachbox{
  height: 2.65rem !important;
  align-items: center;
}
.labelbox{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: normal;
}

.delButton{
  margin: 0.4rem;
  z-index: 1049;
}

.custom-tooltip.tooltip.bs-tooltip-bottomLeft{
  z-index: 1048 !important;
}

.ant-menu-submenu.ant-menu-submenu-inline .ant-menu-item, .ant-menu-submenu-title{
  white-space: initial !important;
  min-height: 2.5rem;
  height: fit-content !important;
  line-height: 1.5rem !important;
}

.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-selected .ant-menu-submenu-arrow{
  color: #2d6cb5 !important;
}

.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-selected .ant-menu-submenu-arrow{
  color: #2d6cb5 !important;
}


.custom-file-input {
  display: inline-block;
  padding: 8px 16px;
  font-size: 16px;
  color: #000000;
  background-color: #007bff;
  border: 1px solid #007bff;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  user-select: none;
  height: 45px;
}

.datadate{
  font-size: 18px;
}

// .content-tabs button.active {
//   width: -webkit-fill-available;
//   margin: 0px 8px;
//   color: #ffffff !important;
//   background-color: #f7922a !important;
// }
// .content-tabs button {
//   width: -webkit-fill-available;
//   margin: 0px 8px !important;
//   color: #ffffff !important;
//   background-color: #f3caa1 !important;
// }
.content-tabs.tab-managemant {
  justify-content: center;
  width: calc(100% + 30px);
  margin-left: -15px;
  .nav-item .nav-link:hover,
  .nav-item .nav-link:focus {
    background-color: #f3caa1;
  }
  .nav-link.disabled {
    background-color: #dfdddd !important;
  }
  .nav-link{
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
  }
  .nav-item{
    margin-right: 1rem;
    width: 18%;
    min-width: 135px;
  }
  .nav-item:last-child{
    margin-right: 0;
  }

  button {
    min-height: 55px;
    font-size: 21px;
    margin: 0px;
    padding-bottom: 9px;
    color: #ffffff !important;
    background-color: #f3caa1 !important;
    border: unset;
  }
  // @media only screen and (max-width: 1024px) {
  //   button {
  //     min-width: 160px;
  //   }
  // }
  // @media only screen and (max-width: 768px) {
  //   button {
  //     min-width: 100px;
  //   }
  // }
  button:hover {
    border: unset;
    background-color: #e29200 !important;
  }
  button.active {
    width: -webkit-fill-available;
    color: #ffffff !important;
    background-color: #f7922a !important;
  }
}
.divider {
  border-bottom: 1px solid #ababab !important;
}
.button-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}
.rbt {
  width: 100%;
}

.ltpb{
  padding-bottom: 0.62rem !important;
}

.video-react-video {
  width: -webkit-fill-available;
  // max-width: 640px;
  max-height: 560px;
}

.video-react-video-line-preview {
  margin: 0;
  width: -webkit-fill-available;
  // max-width: 640px;
  height: 100vh;
}
.bg-app {
  background: #ededed;
  height: 100vh;
}
.bg-lightprimary {
  background-color: #c0e4ff !important;
  color: #000;
}
.audience-group-tab.bg-lightprimary {
  background-color: #c0e4ff !important;
  color: #000 !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.breadcrumb {
  background-color: unset;
  margin-bottom: 0px;
  padding: .75rem 0 !important;
}
.breadcrumb-item a {
  color: #999999;
}
.breadcrumb-item.active {
    color: #2D6CB5;
}
.form-select {
  height: 40px;
  // --bs-form-select-bg-img: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxZW0iIGhlaWdodD0iMWVtIiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIGZpbGw9ImN1cnJlbnRDb2xvciIgZD0iTTEgM2gyMkwxMiAyMiIvPjwvc3ZnPg==");
  --bs-form-select-bg-img: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMjAgNTEyIj48IS0tIUZvbnQgQXdlc29tZSBGcmVlIDYuNS4yIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlL2ZyZWUgQ29weXJpZ2h0IDIwMjQgRm9udGljb25zLCBJbmMuLS0+PHBhdGggZmlsbD0iI2JmYmZiZiIgZD0iTTEzNy40IDM3NC42YzEyLjUgMTIuNSAzMi44IDEyLjUgNDUuMyAwbDEyOC0xMjhjOS4yLTkuMiAxMS45LTIyLjkgNi45LTM0LjlzLTE2LjYtMTkuOC0yOS42LTE5LjhMMzIgMTkyYy0xMi45IDAtMjQuNiA3LjgtMjkuNiAxOS44cy0yLjIgMjUuNyA2LjkgMzQuOWwxMjggMTI4eiIvPjwvc3ZnPg==");

  appearance: none;
  background-color: $white-bg;
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-position: right 0.75rem center;
  background-repeat: no-repeat;
  background-size: 17px;
  border: $border-thin solid #e3e3e3;
  border-radius: 4px;
  color: #333333;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  width: 100%;
}
.form-select:disabled {
  background-color: #F5F5F5;
  color: #888888;
  cursor: not-allowed;
  opacity: 1;
}
.main-panel {
  height: calc(100vh - 77px);
  max-height: calc(100vh - 77px);
  min-height: calc(100vh - 77px);
}
.main-panel > .content {
  padding: unset;
}
.card h4.card-title {
  color: #277ac5;
  padding-left: 10px;
  border-left: solid 20px #277ac5;
  font-weight: bold;
  font-size: 26px;
}
.sidebar {
  overflow: hidden;
  top: 80px;
  max-height: calc(100vh - 80px);

  .nav li.active {
    background-color: #3182CC;
  }
  .nav li .nav-link {
    background: unset !important;
    padding: 5px 5px;
    margin: 0px 15px;

    p {
      text-transform: none;
    }
  }
  .nav li:not(.active) .nav-link {
    color: #2D6CB5;
  }
  .nav .sub-list li .nav-link {
    padding-left: 36px;
  }

  .nav .sub-list li.active .nav-link p{
    color: #ffffff !important;
    font-weight: normal !important;
    text-transform: none;
    margin: 0 !important;
    font-size: 14px !important;
  }

  .nav .sub-list li:not(.active) .nav-link p{
    color: #2D6CB5 !important;
    font-weight: normal !important;
    text-transform: none;
    margin: 0 !important;
    font-size: 14px !important;
  }
}
.sidebar:after {
  background: #ffffff;
}
.btn-primary {
  border-color: #3d93e2;
  color: #3d93e2;
}
.btn-primary.btn-fill {
  background-color: #3d93e2;
}
.btn-primary.btn-fill:hover,
.btn-primary.btn-fill:focus,
.btn-primary.btn-fill:active,
.btn-primary.btn-fill.active,
.open > .btn-primary.btn-fill.dropdown-toggle {
  background-color: #3d93e2;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open > .btn-primary.dropdown-toggle {
  background-color: transparent;
  color: #3d93e2;
  border-color: #3d93e2;
}



.group-action-btn-table .btn:hover {
  background-color: #347BBD !important;
  border-color:  #347BBD !important;
  // opacity: 0.8;
}
.btn-action:hover {
  background-color: #347BBD !important;
  border-color:  #347BBD !important;
}
.group-action-btn-table .btn,
.btn-action.btn,
.btn-action-sm.btn {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  min-width: 100px !important;
  min-height: 28px !important;
  padding: 0px;
  overflow: hidden;

  .iconify {
    font-size: 1.15em;
    margin-bottom: -0.3em;
    margin-top: -0.5em;
  }
}
.group-action-btn-table .btn .save-as,
.btn-action.btn .save-as,
.btn-action-sm.btn .save-as {
  display: flex;
  align-items: center;
  text-align: left;
  gap: 8px;
  font-size: 16px;
  padding: 0px;
  padding-left: 8px;
  margin-top: -2px;

  .iconify {
    margin-top: -2px;
    font-size: 20px;
  }
}

.group-action-btn .btn:hover {
  background-color: #347BBD !important;
  border-color:  #347BBD !important;
  // opacity: 0.8;
}
.btn-action:hover {
  background-color: #347BBD !important;
  border-color:  #347BBD !important;
}
.group-action-btn .btn,
.btn-action.btn,
.btn-action-sm.btn {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  min-width: 110px !important;
  min-height: 40px !important;
  padding: 0px;
  overflow: hidden;

  .iconify {
    font-size: 1.15em;
    margin-bottom: -0.3em;
    margin-top: -0.5em;
  }
}
.group-action-btn .btn .save-as,
.btn-action.btn .save-as,
.btn-action-sm.btn .save-as {
  display: flex;
  align-items: center;
  text-align: left;
  gap: 8px;
  font-size: 16px;
  padding: 0px;
  padding-left: 8px;
  margin-top: -2px;

  .iconify {
    margin-top: -2px;
    font-size: 20px;
  }
}
.btn-action-sm.btn {
  width: 80px;
}
.btn-sm.button-link {
  font-size: 13px;
  height: 30px;
  padding: 0px 4px;
}
.btn-sm {
  font-size: 12px;
  height: 30px;
  padding: 0px 4px;

  .iconify.iconify--tabler {
    font-size: 12px;
    margin-top: -4px;
  }
}
.btn:disabled:hover {
  color: #ffffff !important;
  background-color: #dfdddd !important;
  border-color: #dfdddd !important;
}
.btn:disabled {
  color: #ffffff !important;
  background-color: #dfdddd !important;
  border-color: #dfdddd !important;
}
.btn-back {
  box-sizing: border-box;
  width: 110px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #ababab;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  flex: none;
  order: 4;
  flex-grow: 0;
  color: #ababab;
}
.card {
  .card-header {
    padding: 15px 0;
    border-bottom: 1px solid #ababab !important;
  }
}
.gap-1 {
  gap: 4px;
}
.gap-2 {
  gap: 8px;
}
.gap-3 {
  gap: 16px;
}
.gap-4 {
  gap: 24px;
}
.react-datepicker-wrapper {
  width: 100%;

  input {
    --bs-form-datepicker-bg-img: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAfklEQVR4nO2YMQ6AMAzE7v+fNnsXqA4RQLaUtcolztJEvgdLve29UwywgBu4OJnp2ma6YQyQ+amjQpmfPB5x3lHbTDeMAVKusIS7N/A0GCBuoAIVigpVoEJRoQpUKCpUgQpFhSpQoahQBSqUnymE/0LxY2uLaWUYvkERkZxzACs4xVeSCucjAAAAAElFTkSuQmCC");
    background-image: var(--bs-form-datepicker-bg-img), var(--bs-form-datepicker-bg-icon, none);
    background-position: right 0.75rem center;
    background-repeat: no-repeat;
    background-size: 16px;
  }
}
.rdt_TableRow {
  .form-check {
    font-size: 20px;
    padding-bottom: 1.825rem;
  }
}
.rdt_TableRow:hover {
  background-color: #e2effb;
  border-bottom-color: '#e2effb',
}
.rdt_TableHeadRow {
  background-color: #e2e1e1 !important;
  font-size: medium !important;
  .sc-eTNRI.lnOUep {
    font-size: medium !important;
  }
}
.sc-dmyCSP.fwQJth.rdt_TableHeadRow,
.sc-dmyCSP.mHisf.rdt_TableHeadRow {
  background-color: #e2e1e1;

  .sc-eTNRI.lnOUep {
    font-size: initial;
  }
}
.form-check .form-check-label {
  font-size: inherit;
  color: #000;
  display: inline-block;
  position: relative;
  cursor: pointer;
  line-height: 26px;
  margin-bottom: 0;
  text-transform: capitalize;
  margin-left: 8px;
}
.form-check.form-switch {
  padding-left: 4rem;
}
.form-check-input {
  width: 1em;
  height: 1em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-top-color: rgba(0, 0, 0, 0.25);
  border-right-color: rgba(0, 0, 0, 0.25);
  border-bottom-color: rgba(0, 0, 0, 0.25);
  border-left-color: rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: auto;
}
.form-check-input:checked {
  // background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3E%3C/svg%3E");
  background-color: #3182cc;
  border-color: #3182cc;
  accent-color: #3182cc;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: 0.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
  scale: 1.5;
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.rbt-aux {
  position: absolute;
  top: 8px;
  right: 8px;
}
.width-100 {
  width: 100%;
}
.bg-ededed {
  background-color: #ededed;
}
.start-participlate-button {
  position: absolute;
  bottom: 20px;
  width: 87.8% !important;
}

.start-participlate-button-upper-link {
  position: absolute;
  bottom: 110px;
  width: 87.8% !important;
}
@supports (-webkit-touch-callout: none) {
  .start-participlate-button {
    position: absolute;
    bottom: 20px;
    width: 87.8% !important;
  }
  .start-participlate-button-upper-link {
    position: absolute;
    bottom: 110px;
    width: 87.8% !important;
  }
  .margin-bottom-survey-clear-button {
    margin-bottom: 90px;
  }
}

.empty-space-no-link {
  height: 30px;
}

.empty-space {
  height: 120px;
}

.custom-grey-background{
  background: #FBFBFB;
}

.fix-positon-offer-list {
  position: fixed;
  bottom: 0;
}
.radio input {
  border-radius: 50%;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.rbt-input-wrapper div {
  display: flex;
}
.rbt-input {
  height: 100%;
}
.rbt-input-multi .rbt-input-wrapper {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -4px;
  margin-top: -1px;
  overflow: hidden;
}
.rbt-token {
  background-color: #e7f4ff;
  border: 0;
  border-radius: 0.25rem;
  color: #007bff;
  display: inline-flex;
  line-height: 1rem;
  margin: 1px 3px 2px 0;
}
.rbt-token .rbt-token-label {
  padding: 0.25rem 0.5rem;
}
.flex {
  display: flex;
}
.flex-grow {
  display: flex;
  flex-grow: 1;
}
.align-top {
  vertical-align: top;
}
.right {
  justify-content: end;
}
.title-modal-content {
  color: #2d6cb5;
  font-size: 32px;
}
.modal-header .btn-close {
  min-height: 35px;
  min-width: 35px;
  border: unset;
  background-color: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='53.7' height='53.7' viewBox='0 0 53.7 53.7'><path opacity='.6' fill='%23666E6E' d='M35.6 34.4L28 26.8l7.6-7.6c.2-.2.2-.5 0-.7l-.5-.5c-.2-.2-.5-.2-.7 0l-7.6 7.6-7.5-7.6c-.2-.2-.5-.2-.7 0l-.6.6c-.2.2-.2.5 0 .7l7.6 7.6-7.6 7.5c-.2.2-.2.5 0 .7l.5.5c.2.2.5.2.7 0l7.6-7.6 7.6 7.6c.2.2.5.2.7 0l.5-.5c.2-.2.2-.5 0-.7z'/></svg>");
  background-size: contain;
}
.preview-button {
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-wrap: nowrap;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 4px;
  padding-right: 4px;
}
.preview-button:hover {
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #347BBD !important;
  border-color:  #347BBD !important;
}
.export-button {
  position: absolute;
  top: 0.5rem;
  left: calc(100% - 7.8rem);
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-wrap: nowrap;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 16px;
  padding-right: 16px;
}
.export-button:hover {
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #347BBD !important;
  border-color:  #347BBD !important;
}
.label-input-file-csv{
  // display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 80%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  // padding: 5px 10px;
  color: #999999;
  font-size: 14px !important;
  margin-bottom: 0px !important;
  text-transform:none !important;
  // color: #000000 !important;
  padding: 10px !important;
}
.file-export{
  margin-right: 8rem;
}
.delete-message{
  position: absolute;
  top: 54%;
  padding-right: 2rem;
}
.custom-body-padding-delete-message{
  padding-bottom: 4.4rem !important;
}
.poiter {
  cursor: pointer;
}
.input-preview {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  // padding: 7px;
  display: flex;
  justify-content: space-between;
  line-height: 1;
  height: 40px;
}
.input-show-preview {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  background-color: white;
}
.input-no-preview-csv {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  display: flex;
  justify-content: center;
  line-height: 1;
  margin-right: 7.8rem;
  height: 57px;
}

.input-show-preview .preview-header {
  display: flex;
  justify-content: space-between;
  line-height: 1;
  padding: 10px;
  padding-inline: 16px;
}

.input-show-preview .preview-body {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: 20px;
  padding-top: 0px;
}

.preview-image {
  border-radius: 10px;
}

.preview-camera-icon {
  width: 20px;
  height: auto;
  opacity: 0.7;
}

.show-preview-label-input-file {
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 80%;
  flex-grow: 1;
  justify-content: start;
  display: flex;
  cursor: pointer;
  color: #999999;
  font-size: 14px !important;
  margin-bottom: 0px !important;
  text-transform:none !important;
  padding: 10px !important;
}

.label-input-file {
  // display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 80%;
  flex-grow: 1;
  justify-content: center;
  display: flex;
  cursor: pointer;
  // padding: 5px 10px;
  color: #999999;
  font-size: 14px !important;
  margin-bottom: 0px !important;
  text-transform:none !important;
  // color: #000000 !important;
  padding: 10px !important;
}
.space-between {
  justify-content: space-between;
}
.button-lov {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 4px;
  padding-right: 4px;
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  border: none;
}
.text-red{
  color: red;
}
.icon-close {
  color: red;
  cursor: pointer;
}
.font-bold {
  font-weight: 700 !important;
}
.flex-end {
  justify-content: flex-end !important;
}
.primary-color {
  background: #cccccc;
}
.secondary-color {
  background: #2d6cb5;
}

.requireText {
  color: #f00;
  font-weight: bold;
}

.container-center {
  display: flex; /* Use flexbox layout */
  justify-content: center; /* Horizontally center children */
  align-items: center; /* Vertically center children */
  // height: 100vh; /* Adjust as needed */
}

.centered-content {
  text-align: center; /* Optionally center text within the content */
}

.marginTop2 {
  margin-top: 2;
}

.custom-checkbox .custom-control-label {
  color: red; /* Change the font color of the label */
}

.borderRounded {
  border: 1px solid rgba(0, 0, 0, 0.13);
  padding: 20px;
  border-radius: 15px;
}

.alignCenterContainer {
  margin-left: 40px;
  margin-right: 40px;
}

.activity-tabs button {
  width: -webkit-fill-available;
  margin: 0px 8px;
  color: #999999;
  background-color: white;
  border: 0px;
  border-bottom: solid;
  border-bottom-width: 10px;
  border-bottom-color: #f3caa1;
}
.activity-tabs button:hover {
  border-bottom-color: #e29200;
}
.activity-tabs button.active {
  width: -webkit-fill-available;
  margin: 0px 8px;
  color: #3d93e2 !important;
  background-color: white !important;
  border-bottom: solid;
  border-bottom-width: 10px;
  border-bottom-color: #ffa500;
}

.alignRight {
  align-items: right;
}

.touchpointScheduleTitle {
  padding: 12px;
  // border-bottom: 3px solid #3D93E2;
}
.touchpointScheduleOnetime {
  padding: 18px;
}
.touchpointScheduleOnetimeTitle {
  font-size: 16pt;
}
.touchpointScheduleOnetimeDetail {
  margin: 18px;
}
.touchpointScheduleOnetimeDetailRow {
  margin: 16px 0px;
}
.tableSchedule {
  border-top: 3px solid #3d93e2;
  border-bottom: 3px solid #3d93e2;
}
.tdSchedule {
  padding: 8px 0px;
  border-right: 3px solid #3d93e2;
}
.tdScheduleDetail {
  padding: 8px;
}
.touchpointScheduleDetail {
  padding: 18px;
  vertical-align: top;
}
.input-text {
  height: 40px;
}

.boxShadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.sweetalert-icon-warning {
  border-color: #ffb800 !important;
  background: #ffb800 !important;
  .swal2-icon-content {
    color: #ffffff;
    display: flex;
    align-items: center;
    font-size: 3.75em;
    font-family: sans-serif !important;
  }
}

.sweetalert-icon-error {
  border-color: #ff0000 !important;
  background: #ff0000 !important;
  .swal2-icon-content {
    color: #ffffff;
    display: flex;
    align-items: center;
    font-size: 3.75em;
    font-family: sans-serif !important;
  }
}

.sweetalert-icon-info {
  border-color: #3d93e2 !important;
  background: #3d93e2 !important;
  .swal2-icon-content {
    color: #ffffff;
    display: flex;
    align-items: center;
    font-size: 3.75em;
    font-family: sans-serif !important;
  }
}
.sweetalert-table {
  width: 100%;
}
.sweetalert-table-row {
  font-size: 14px;
  text-align: left;
  color: #999999;
  font-family: "Sarabun", sans-serif !important;
}
.sweetalert-title{
  font-size:26px;
}
.sweetalert-popup{
  min-height: 365px;
}

  .rvs-select > div:nth-of-type(2) > div:first-of-type > div {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 2px 8px;
  }
  .rvs-select > div:nth-of-type(2) > div:first-of-type > div[aria-selected="true"] {
    background-color: #fff;
    color: inherit;
  }
  .rvs-select > div:nth-of-type(2) > div:first-of-type > div::before {
    content: "";
    display: block;
    border-radius: 2px;
    width: 20px;
    height: 20px;
  }
  .rvs-select > div:nth-of-type(2) > div:first-of-type > div::before {
    border: 1px solid rgba(0, 0, 0, 0.25);
  }
  .rvs-select > div:nth-of-type(2) > div:first-of-type > div[aria-selected="true"]::before {
    background:
      url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3E%3Cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3E%3C/svg%3E")
        CENTER CENTER NO-REPEAT,
      #3182cc;
    border-color: #3182cc;
  }

// .rvs-select > div:nth-of-type(2) > div:first-of-type > div[aria-selected="true"]::before {
//   background:
//     url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3E%3Cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3E%3C/svg%3E")
//       CENTER CENTER NO-REPEAT,
//     #3182cc;
//   border-color: #3182cc;
// }
.rvs-select > div:first-of-type > div:first-of-type {
  overflow-x: auto;
  align-content: start;
  // flex-direction: column;
  scrollbar-width: none;
  // max-height: 40px;
}
.rvs-select > div > div:last-of-type > span {
  display: none;
}
.rvs-select > div > div:last-of-type > div:last-of-type {
  span {
    display: none;
  }
  svg {
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxZW0iIGhlaWdodD0iMWVtIiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxnIGZpbGw9Im5vbmUiPjxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiLz48cGF0aCBmaWxsPSJibGFjayIgZD0iTTEwLjUgMmE4LjUgOC41IDAgMCAxIDYuNjc2IDEzLjc2MmwzLjY1MiAzLjY1MmExIDEgMCAwIDEtMS40MTQgMS40MTRsLTMuNjUyLTMuNjUyQTguNSA4LjUgMCAxIDEgMTAuNSAybTAgMmE2LjUgNi41IDAgMSAwIDAgMTNhNi41IDYuNSAwIDAgMCAwLTEzbTAgMWE1LjUgNS41IDAgMSAxIDAgMTFhNS41IDUuNSAwIDAgMSAwLTExIi8+PC9nPjwvc3ZnPg==")
      CENTER CENTER NO-REPEAT;
    background-size: 24px;
    opacity: 0.5;

    path {
      display: none;
    }
  }
}
.datatable-wrapper {
  height: 500px;
  overflow-y: auto;
}
.modal-bzb {
  transform: none !important;
  .modal-content{
    .modal-header{
      padding: 15px 15px 15px 24px;
      border-bottom: 1px solid #dee2e6;
    }
    .modal-body::-webkit-scrollbar {
      display: none;
    }
    .modal-body {
      padding-top: 0;
      padding-right: 0;
      padding-bottom: 0;
      padding-left: 0;
      line-height: 1.9;
      // overflow-y:hidden;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      // .card{
        // .card-body .datatable-wrapper{
        //     height: none;
        //     overflow-y: hidden;
        // }
      // }
    }
  }
  .card{
    border:none
  }
}
.video-react-button {
  display: none;
}
.video-react-control-bar {
  display: none;
}

.btnDetailTable {
  // font-weight: 600;
  // font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  // min-width: 110px !important;
  // min-height: 40px !important;
  padding: 0px;
  overflow: hidden;


}
.btnDetailTable:focus {
  // font-weight: 600;
  // font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  // min-width: 110px !important;
  // min-height: 40px !important;
  padding: 0px;
  overflow: hidden;


}
.btnDetailTable:hover {
  // font-weight: 600;
  // font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  // min-width: 110px !important;
  // min-height: 40px !important;
  background-color: #347BBD !important;
  border-color:  #347BBD !important;


}

.btnTableWarning {
  @extend .btnDetailTable;
  color: white;
  background-color: #E29200 !important;
  border-color:  #E29200 !important;
}

.btnTableWarning:focus {
  color: white;
}

.btnTableWarning:hover {
  color: white;
  background-color: #B27203 !important;
  border-color:  #B27203 !important;
}

.dzHEgY {
  flex-grow: 0 !important;
}
.modal.show .modal-dialog {
  transform: none !important; 

}
.iconAuto {
  position: absolute;
  top:11px;
  right: 4px;
  font-size: 18px;
  color: #878787;
}
.tableInPopup input[type="checkbox"] {
  width: 18px;
  height: 18px;
}
.tableInPopup .rdt_TableBody {
  max-height: 25vh;
  overflow: hidden;
  overflow-y: scroll;
  
  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 10px 10px 10px rgba(0, 0, 0, 0);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #4e4e4e;
    border-radius: 100px;
    background-clip: padding-box;
    border: 2px solid rgba(0, 0, 0, 0);
    // display: none;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #4e4e4e;
    background-clip: padding-box;
    border: 2px solid rgba(0, 0, 0, 0);
    // display: block;
  }

  &::-webkit-scrollbar:vertical {
    display: none;
  }
}
.rdt_TableRow:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: #e2effb;
  transition-duration: 0.15s;
  transition-property: background-color;
  border-bottom-color: #e2effb !important;
  outline-style: solid;
  outline-width: 1px;
  outline-color: #e2effb;
  cursor: pointer;
}
.auto-complete-icon input:not([aria-hidden="true"]) {
  padding-right: 25px;
  background: url("../../assets/img/search.svg") no-repeat right;
  background-size: 20px;
  background-position: calc(100% - 2px) center;
  // border-right: 1px solid #E3E3E3 !important;
}
.auto-complete-icon input:disabled:not([aria-disabled="true"]) {
  padding-right: 25px;
  background: #f5f5f5 url("../../assets/img/search.svg") no-repeat right;
  background-size: 20px;
  background-position: calc(100% - 2px) center;
  // border-right: 1px solid #E3E3E3 !important;
}
.activityTitleModal{
  color: #2d6cb5;
  font-size: 26px;
  padding: 0px;
  // margin: 0px;
  font-weight: bold;
  margin: 0;
}
.modal-body {
  max-height: 70vh;
  overflow: hidden;
  overflow-y: scroll;
}
.ant-picker-input input {
  font-family: "Sarabun", sans-serif !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
}
.btn-action-sm:hover {
  background-color: #347BBD !important;
  border-color:  #347BBD !important;
  // opacity: 0.8;
}
.btn-tog {
  color: #3182CC !important;
  background-color: unset !important;
  border: unset !important;
  font-size: 30px !important;
  margin-right: 16px !important;
  padding-right: 16px !important;
  border-radius: 0px !important;
  border-right: 4px solid #3182CC !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.headerWeb {
  background-color: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 999;
}
.navUserName {
  text-align: center;
  margin-top: 8px;
  border-left: 4px solid #3182CC !important;
  border-right: 4px solid #3182CC !important;
  padding: 0px 12px;
  color: #999999;
}
.navName {
  font-size: 16px;
  font-weight: bold;
}
.navTeam {
  font-size: 12px;
  font-weight: bold;
}
.none-scroll::-webkit-scrollbar {
  display: none;
}
.none-scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
// .none-scroll{
//   overflow: unset;
//   overflow-y: unset;
// }

.text-box-custom-survey{
  margin-bottom: 18px !important;
}

.text-box-custom-survey2{
  margin-bottom: 17px !important;
}

.nav-open .sidebar {
  -webkit-transform: translate3d(-250px, 0, 0);
  -moz-transform: translate3d(-250px, 0, 0);
  -o-transform: translate3d(-250px, 0, 0);
  -ms-transform: translate3d(-250px, 0, 0);
  transform: translate3d(-250px, 0, 0) !important;
  -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  -moz-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  -o-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  -ms-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1); 
}
  .nav-open .main-panel {
    width: 100%;
    position: absolute;
    right: 0;
    // transform: translate3d(-250px, 0, 0) !important;
    transition: all 0.5s cubic-bezier(1, 0.0473, 0.346, 0.6); 
  // transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}
.video-react-fullscreen .video-react-video{
  max-height: 100vh;
}
.tox-statusbar__right-container {
  display: none !important;
}
.countOfEditor {
  font-size: 12px;
  color: rgba(34, 47, 62, .7);
  position: absolute;
  bottom: 6px;
  right: 35px;
}
.countOfEditorModal {
  font-size: 12px;
  color: rgba(34, 47, 62, .7);
  position: absolute;
  bottom: 6px;
  right: 35px;
  line-height: 1.5;
}
.countOfEditorCallplan{
  font-size: 12px;
  color: rgba(34, 47, 62, .7);
  position: absolute;
  bottom: 6px;
  right: 44px;
}
.recOfEditor {
  font-size: 12px;
  color: #757575;
  position: absolute;
  bottom: 6px;
  left: 35px;
}
.input-preview.disabled {
  background-color: #F5F5F5;
  color: #888888;
}
.smsShow {
  // mar
  margin-top: 10px;
  position: relative;
  margin-left: 20px;
  margin-bottom: 10px;
  padding: 14px;
  background-color: #dfdddd ;
  width: 90%;
  min-height: 50px;
  text-align: left;
  font: 400 .9em 'Open Sans', sans-serif;
  // border: 1px solid #97C6E3;
  border-radius: 10px;
  white-space: normal;
  overflow-wrap: break-word;
}
.smsShow:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 17px solid #dfdddd ;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  bottom: 0px;
  left: -15px;
}

.custom-lineheight{
  line-height: 18px;
}

.require-label{
  font-size: 10px;
}

.topic-preview-email{
  font-size: 1.4rem;
  // white-space: normal;
}
.profile{
  border-radius: 5rem;
  background-color: #0177d5;
  color: white;
  width: 3.5rem;
  height: 3.5rem;
  font-size: 1.5rem;
  font-weight: 300;
}
.file-box{
  border-radius: 0.5rem;
  border: grey solid 1px;
}
.fileName{
  white-space: nowrap;           
  overflow: hidden;              
  text-overflow: ellipsis;
  width: fit-content;
  height: fit-content !important;
  line-height: 1.5rem !important;
}
.icon {
  font-size: 3rem;
  color: #999999;
}
.email-attach{
  padding-right: 2rem !important;
}
.count-text{
  line-height: 0.8;
}
.card-header:first-child.radius-10px{
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}

//line content
.card.line-message-card{
  .card-header.line-message-card-header {
    padding: 15px 0;
    border-bottom: 1px solid transparent !important;
  }
}
.card.line-message-card{
  .card-header.line-message-cardIn-card-header {
    padding: 15px 0;
    border-bottom: 1px solid transparent !important;
  }
}
.line-message-card-header{
  background-color: #f3caa1 !important;
  margin: 0 !important;
  padding: 0.5rem !important;
  height: 55px;
  font-weight: 500;
}
.line-message-cardIn-card-header{
  background-color: #efefef !important;
  margin: 0 !important;
  padding: 0.5rem !important;
  height: 55px;
}
.card.line-chat {
  border: none;
}
.chatBox{
  cursor: pointer;
}
.chat-header{
  position: fixed;
  top: 0;
  background-color: #303e57;
  color: #f6f6f6;
  width: 100vw;
  height: 3rem;
  align-content: center;
  text-align: center;
  z-index: 50;
}
.chat{
  background-color: #8cabd9;
  padding-top: 3rem;
  min-height: 100vh;
  height: fit-content;
  z-index: 1;
}
.arrow {
  position: fixed;
  top: 0.7rem;
  font-size: 24px;
  color: #f6f6f6;
  z-index: 51;
}
.arrow-popup {
  position: fixed;
  top: 1rem;
  left: 0.7rem;
  font-size: 24px;
  color: #f6f6f6;
  z-index: 11;
}
.clickable{
  cursor: pointer;
}
.text-chat{
  margin: 1rem;
  margin-left: 1rem;
  margin-right: 5rem;
  background-color: #ffffff;
  border-radius: 0.8rem;
  border: none;
  padding: 0.5rem;
  line-height: 1.7;
  max-width: 20rem;
  z-index: 4;
  white-space: normal;
  overflow-wrap: break-word;
}
.time-chat{
  font-size: 13px;
}
.time-box{
  margin-left: 15.4rem;
}
.logo-toyota{
  border-radius: 10rem;
  margin-top: 0.4rem;
}
.notice{
  font-size: 14px;
  background-color: #78e378;
  border-radius: 10rem;
  color: #ffffff;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  margin-top: 0.8rem;
  line-height: 12px;
  height: 1rem;
}
@mixin line-clamp($lines) {
  display: -webkit-box; 
  -webkit-box-orient: vertical; 
  -webkit-line-clamp: $lines; 
  overflow: hidden; 
  text-overflow: ellipsis; 
}
.text-preview {
  max-width: 19.5rem; 
  max-height: 2.7rem; 
  // padding: 0.2rem 0;
  @include line-clamp(2); 
}
.text-line {
  color: #8a909f;
  font-size: 13px;
  line-height: 1.7; 
}
.today{
  color: #ffffff;
  margin: auto;
  margin-top: 0.8rem;
  width: fit-content;
  background-color: #0000002b;
  font-size: 12px;
  line-height: 1.8;
  border-radius: 10rem;
}
.message-arrow{
  position: absolute;
  margin-top: 0.78rem;
  margin-left: 3.45rem;
  z-index: 3;
}
.time-text{
  margin-bottom: 0.8rem;
  margin-left: 26.5rem;
  background-color: #8cabd9;
  color: #ffffff;
  font-size: 12px;
}
.time-text-image{
  padding-bottom: 0.1rem;
  margin-left: 27.4rem;
  background-color: #8cabd9;
  color: #ffffff;
  font-size: 12px;
}
.time-text-slide{
  position: absolute;
  margin-top: -2.25rem;
  margin-left: 27.4rem;
  background-color: #8cabd9;
  color: #ffffff;
  font-size: 12px;
}
.popup-video{
  background-color: #303e57;
  margin-left: 0rem;
}
.line.modal-open div.fade.modal-backdrop.show{
  background-color: #303e57;
  opacity: 1;
}
.popup-video::-webkit-scrollbar {
  display: none; 
}
.popup-video div.modal-content{
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  margin-left: -0.5rem;
  background-color: #000000;
  padding: 0;
  width: 447px;
  height: 765px;
  margin-top: 4rem;
}
.video-modal{
  background-color: #303e57;
  color: #ffffff;
}
.popup-center{
  position: fixed;
  top: 1.2rem;
  left: 3rem;
  background-color: #303e57;
  z-index: 12;
}
// .videoPopup{

// }
.play-button{
  color: #ffffff;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  margin-top: 255px;
  z-index: 11;
}
.video-preview{
  z-index: 9;
}
.overlay{
  display:inline-block;
  position:absolute;
  margin-top: 0.5rem;
  padding: 0;
  left: 0;
  margin-left: 0.5rem;
  width: 460px; 
  height: 600px;
  background-color: rgba(0,0,0,0.2);
  z-index: 10;
}
.popup-header{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 4rem;
  background-color: #303e57;
  z-index: 90;
}
.link-label{
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  margin-top: 34rem;
  color: #ffffff;
  width: fit-content;
  background-color: #00000054;
  font-size: 16px;
  padding: 0.3rem 0.6rem;
  border-radius: 10rem;
  z-index: 40;
}
.slider-container {
  overflow-x: auto; 
  white-space: nowrap; 
  width: 100%; 
  border: none; 
  overflow-y: hidden;
  height: 335px;
  margin-bottom: 0.5rem;
}
.slider-container::-webkit-scrollbar{
  background-color: transparent;
  height: 0.5rem;
}
.slider-container::-webkit-scrollbar-thumb{
  background-color: #9f9f9f;
  border-radius: 10rem;
  scrollbar-width: 5rem;
}
.slider-container::-webkit-scrollbar-thumb:hover {
  background-color: #d1d1d1; /* Darker color on hover */
}
.slider {
  display: inline-flex; 
  padding-right: 0.5rem;
}
.slider-item {
  display: inline-block; 
  min-width: 300px; 
  height: fit-content; 
  margin-right: 0.1rem;
  margin-top: 0.4rem; 
  background-color: transparent; 
  padding-left: 0.5rem;
}
.image-in-card{
  border-radius: 1.5rem;
}
.card-label {
  cursor: pointer;
  position: relative;
  width: fit-content;
  left: 50%;
  transform: translateX(-50%);
  height: fit-content; /* This is generally not necessary unless you have specific height requirements */
  padding-block: 0.5rem;
  padding-inline: 0.5rem;
  border-radius: 10rem;
  font-size: 13px;
  bottom: 3rem;
  background-color: #00000070;
  color: #ffffff;
}
body.line::-webkit-scrollbar {
  display: none;
}
.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
  color: rgba(19, 112, 218, 0.7) !important; 
}
.radius-10px{
  border-radius: 10px;
}
.card-header.none-bottom-border{
  border-bottom: 1px solid transparent !important;
}

.card-touchpoint{
  background-color: #e2e1e1 !important;
  margin: 0 !important;
  padding: 0.5rem !important;
  height: 55px;
}

//Call plan
.dialog div.tox-editor-header{
  display: none;
}


.ant-picker-outlined:focus, .ant-picker-outlined:focus-within,.ant-picker-outlined:hover {
  border-color: #1677ff;
  box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
  outline: 0;
  background-color: #ffffff;
}

  .form-control:focus:not([disabled]):not([autocomplete="off"]):not([placeholder="BZB Campaign"]), 
  .form-control:hover:not([disabled]):not([autocomplete="off"]):not([placeholder="BZB Campaign"]), 
  .form-select:focus:not([disabled]):not([autocomplete="off"]):not([placeholder="BZB Campaign"]),  
  .form-select:hover:not([disabled]):not([autocomplete="off"]):not([placeholder="BZB Campaign"])  {
    border-color: #1677ff;
    box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
    outline: 0;
    background-color: #ffffff;
  }
 

 .pt-01 {
  padding-top: 0.1rem !important
 }
 .tox-statusbar__path-item {
  display: none !important;
 }
 .tox-statusbar__path-divider {
  display: none !important;
 }
 .mb-n6 {
  margin-bottom: -4rem !important;
 }

 .text-button{
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
 }
 
 .viewTableContent .rdt_TableHeadRow .rdt_TableCol:nth-child(1) {
  position: sticky;
  background-color:#e2e1e1 ;
  left: 0;
  z-index: 2;
 }
 .viewTableContent .rdt_TableRow .rdt_TableCell:nth-child(1) {
  position: sticky;
  background-color:#fff ;
  left: 0;
  z-index: 1;
 }
 .viewTableContent .rdt_TableRow:hover .rdt_TableCell:nth-child(1) {
  position: sticky;
  background-color: #e2effb !important;
  transition-duration: 0.15s;
  transition-property: background-color;
  border-bottom-color: #e2effb !important;
  left: 0;
  z-index: 1;
 }
 .viewTableContent .rdt_TableHeadRow .rdt_TableCol:nth-child(2) {
  position: sticky;
  background-color:#e2e1e1 ;
  left: 350px;
  z-index: 2;
 }
 .viewTableContent .rdt_TableRow .rdt_TableCell:nth-child(2) {
  position: sticky;
  background-color:#fff ;
  left: 350px;
  z-index: 1;
 }
 .viewTableContent .rdt_TableRow:hover .rdt_TableCell:nth-child(2) {
  position: sticky;
  background-color: #e2effb !important;
  transition-duration: 0.15s;
  transition-property: background-color;
  border-bottom-color: #e2effb !important;
  left: 350px;
  z-index: 1;
 }
 
 
 .viewTableTouchpoint .rdt_TableHeadRow .rdt_TableCol:nth-child(1) {
  position: sticky;
  background-color:#e2e1e1 ;
  left: 0;
  z-index: 1;
 }
 .viewTableTouchpoint .rdt_TableRow .rdt_TableCell:nth-child(1) {
  position: sticky;
  background-color:#fff ;
  left: 0;
  z-index: 1;
 }
 .viewTableTouchpoint .rdt_TableRow:hover .rdt_TableCell:nth-child(1) {
  position: sticky;
  background-color: #e2effb !important;
  transition-duration: 0.15s;
  transition-property: background-color;
  border-bottom-color: #e2effb !important;
  left: 0;
  z-index: 1;
 }
 .viewTableTouchpoint .rdt_TableHeadRow .rdt_TableCol:nth-child(2) {
  position: sticky;
  background-color:#e2e1e1 ;
  left: 120px;
  z-index: 1;
 }
 .viewTableTouchpoint .rdt_TableRow .rdt_TableCell:nth-child(2) {
  position: sticky;
  background-color:#fff ;
  left: 120px;
  z-index: 1;
 }
 .viewTableTouchpoint .rdt_TableRow:hover .rdt_TableCell:nth-child(2) {
  position: sticky;
  background-color: #e2effb !important;
  transition-duration: 0.15s;
  transition-property: background-color;
  border-bottom-color: #e2effb !important;
  left: 120px;
  z-index: 1;
 }
 .viewTableTouchpoint .rdt_TableHeadRow .rdt_TableCol:nth-child(3) {
  position: sticky;
  background-color:#e2e1e1 ;
  left: 470px;
  z-index: 1;
 }
 .viewTableTouchpoint .rdt_TableRow .rdt_TableCell:nth-child(3) {
  position: sticky;
  background-color:#fff ;
  left: 470px;
  z-index: 1;
 }
 .viewTableTouchpoint .rdt_TableRow:hover .rdt_TableCell:nth-child(3) {
  position: sticky;
  background-color: #e2effb !important;
  transition-duration: 0.15s;
  transition-property: background-color;
  border-bottom-color: #e2effb !important;
  left: 470px;
  z-index: 1;
 }
//  .viewTableTouchpoint .rdt_TableHeadRow .rdt_TableCol:nth-child(4) {
//   position: sticky;
//   background-color:#e2e1e1 ;
//   left: 720px;
//   z-index: 1;
//  }
//  .viewTableTouchpoint .rdt_TableRow .rdt_TableCell:nth-child(4) {
//   position: sticky;
//   background-color:#fff ;
//   left: 720px;
//   z-index: 1;
//  }
//  .viewTableTouchpoint .rdt_TableRow:hover .rdt_TableCell:nth-child(4) {
//   position: sticky;
//   background-color: #e2effb !important;
//   transition-duration: 0.15s;
//   transition-property: background-color;
//   border-bottom-color: #e2effb !important;
//   left: 720px;
//   z-index: 1;
//  }
//  .viewTableTouchpoint .rdt_TableHeadRow .rdt_TableCol:nth-child(5) {
//   position: sticky;
//   background-color:#e2e1e1 ;
//   left: 970px;
//   z-index: 1;
//  }
//  .viewTableTouchpoint .rdt_TableRow .rdt_TableCell:nth-child(5) {
//   position: sticky;
//   background-color:#fff ;
//   left: 970px;
//   z-index: 1;
//  }
//  .viewTableTouchpoint .rdt_TableRow:hover .rdt_TableCell:nth-child(5) {
//   position: sticky;
//   background-color: #e2effb !important;
//   transition-duration: 0.15s;
//   transition-property: background-color;
//   border-bottom-color: #e2effb !important;
//   left: 970px;
//   z-index: 1;
//  }


 
 .viewTableParameter .rdt_TableHeadRow .rdt_TableCol:nth-child(1) {
  position: sticky;
  background-color:#e2e1e1 ;
  left: 0;
  z-index: 3;
 }
 .viewTableParameter .rdt_TableRow .rdt_TableCell:nth-child(1) {
  position: sticky;
  background-color:#fff ;
  left: 0;
  z-index: 3;
 }
 .viewTableParameter .rdt_TableRow:hover .rdt_TableCell:nth-child(1) {
  position: sticky;
  background-color: #e2effb !important;
  transition-duration: 0.15s;
  transition-property: background-color;
  border-bottom-color: #e2effb !important;
  left: 0;
  z-index: 3;
 }
 .viewTableParameter .rdt_TableHeadRow .rdt_TableCol:nth-child(2) {
  position: sticky;
  background-color:#e2e1e1 ;
  left: 400px;
  z-index: 2;
 }
 .viewTableParameter .rdt_TableRow .rdt_TableCell:nth-child(2) {
  position: sticky;
  background-color:#fff ;
  left: 400px;
  z-index: 2;
 }
 .viewTableParameter .rdt_TableRow:hover .rdt_TableCell:nth-child(2) {
  position: sticky;
  background-color: #e2effb !important;
  transition-duration: 0.15s;
  transition-property: background-color;
  border-bottom-color: #e2effb !important;
  left: 400px;
  z-index: 2;
 }
 .viewTableParameter .rdt_TableHeadRow .rdt_TableCol:nth-child(3) {
  position: sticky;
  background-color:#e2e1e1 ;
  left: 400px;
  z-index: 1;
 }
 .viewTableParameter .rdt_TableRow .rdt_TableCell:nth-child(3) {
  position: sticky;
  background-color:#fff ;
  left: 400px;
  z-index: 1;
 }
 .viewTableParameter .rdt_TableRow:hover .rdt_TableCell:nth-child(3) {
  position: sticky;
  background-color: #e2effb !important;
  transition-duration: 0.15s;
  transition-property: background-color;
  border-bottom-color: #e2effb !important;
  left: 400px;
  z-index: 1;
 }
 .ant-switch.ant-switch-checked{
  background: #3182cc;
 }
 .tox .tox-tbtn__select-label {
  text-overflow: inherit;
 }
 .divRow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1px;
 }
 .divRow2 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px;
}

//  ul.content-tabs {
//   display: flex !important;
//   justify-content: space-between !important;
//  }

//  .tabContent{
//   margin-left: 0.88rem;
//   margin-right: 1.4rem;
//  }


 .viewTableParameterDtl .rdt_TableHeadRow .rdt_TableCol:nth-child(1) {
  position: sticky;
  background-color:#e2e1e1 ;
  left: 0;
  z-index: 1;
 }
 .viewTableParameterDtl .rdt_TableRow .rdt_TableCell:nth-child(1) {
  position: sticky;
  background-color:#fff ;
  left: 0;
  z-index: 1;
 }
 .viewTableParameterDtl .rdt_TableRow:hover .rdt_TableCell:nth-child(1) {
  background-color: #e2effb !important;
  transition-duration: 0.15s;
  transition-property: background-color;
  border-bottom-color: #e2effb !important;
 }
 

 //table activity
 .viewTableActivity .rdt_TableHeadRow .rdt_TableCol:nth-child(1) {
  position: sticky;
  background-color:#e2e1e1 ;
  left: 0;
  z-index: 3;
 }
 .viewTableActivity .rdt_TableRow .rdt_TableCell:nth-child(1) {
  position: sticky;
  background-color:#fff ;
  left: 0;
  z-index: 3;
 }
 .viewTableActivity .rdt_TableRow:hover .rdt_TableCell:nth-child(1) {
  position: sticky;
  background-color: #e2effb !important;
  transition-duration: 0.15s;
  transition-property: background-color;
  border-bottom-color: #e2effb !important;
  left: 0;
  z-index: 3;
 }
 .viewTableActivity .rdt_TableHeadRow .rdt_TableCol:nth-child(2) {
  position: sticky;
  background-color:#e2e1e1 ;
  left: 250px;
  z-index: 2;
 }
 .viewTableActivity .rdt_TableRow .rdt_TableCell:nth-child(2) {
  position: sticky;
  background-color:#fff ;
  left: 250px;
  z-index: 2;
 }
 .viewTableActivity .rdt_TableRow:hover .rdt_TableCell:nth-child(2) {
  position: sticky;
  background-color: #e2effb !important;
  transition-duration: 0.15s;
  transition-property: background-color;
  border-bottom-color: #e2effb !important;
  left: 250px;
  z-index: 2;
 }
 .viewTableActivity .rdt_TableHeadRow .rdt_TableCol:nth-child(3) {
  position: sticky;
  background-color:#e2e1e1 ;
  left: 250px;
  z-index: 1;
 }
 .viewTableActivity .rdt_TableRow .rdt_TableCell:nth-child(3) {
  position: sticky;
  background-color:#fff ;
  left: 250px;
  z-index: 1;
 }
 .viewTableActivity .rdt_TableRow:hover .rdt_TableCell:nth-child(3) {
  position: sticky;
  background-color: #e2effb !important;
  transition-duration: 0.15s;
  transition-property: background-color;
  border-bottom-color: #e2effb !important;
  left: 250px;
  z-index: 1;
 }
 
 .viewTableCareCampaign .rdt_TableHeadRow .rdt_TableCol:nth-child(1) {
  position: sticky;
  background-color:#e2e1e1 ;
  left: 0;
  z-index: 1;
 }
 .viewTableCareCampaign .rdt_TableRow .rdt_TableCell:nth-child(1) {
  position: sticky;
  background-color:#fff ;
  left: 0;
  z-index: 1;
 }
 .viewTableCareCampaign .rdt_TableRow:hover .rdt_TableCell:nth-child(1) {
  position: sticky;
  background-color: #e2effb !important;
  transition-duration: 0.15s;
  transition-property: background-color;
  border-bottom-color: #e2effb !important;
  left: 0;
  z-index: 1;
 }
 .viewTableCareCampaign .rdt_TableHeadRow .rdt_TableCol:nth-child(2) {
  position: sticky;
  background-color:#e2e1e1 ;
  left: 350px;
  z-index: 1;
 }
 .viewTableCareCampaign .rdt_TableRow .rdt_TableCell:nth-child(2) {
  position: sticky;
  background-color:#fff ;
  left: 350px;
  z-index: 1;
 }
 .viewTableCareCampaign .rdt_TableRow:hover .rdt_TableCell:nth-child(2) {
  position: sticky;
  background-color: #e2effb !important;
  transition-duration: 0.15s;
  transition-property: background-color;
  border-bottom-color: #e2effb !important;
  left: 350px;
  z-index: 1;
 }

 .viewTableThe1Campaign .rdt_TableHeadRow .rdt_TableCol:nth-child(1) {
  position: sticky;
  background-color:#e2e1e1 ;
  left: 0;
  z-index: 1;
 }
 .viewTableThe1Campaign .rdt_TableRow .rdt_TableCell:nth-child(1) {
  position: sticky;
  background-color:#fff ;
  left: 0;
  z-index: 1;
 }
 .viewTableThe1Campaign .rdt_TableRow:hover .rdt_TableCell:nth-child(1) {
  position: sticky;
  background-color: #e2effb !important;
  transition-duration: 0.15s;
  transition-property: background-color;
  border-bottom-color: #e2effb !important;
  left: 0;
  z-index: 1;
 }
 .viewTableThe1Campaign .rdt_TableHeadRow .rdt_TableCol:nth-child(2) {
  position: sticky;
  background-color:#e2e1e1 ;
  left: 150px;
  z-index: 1;
 }
 .viewTableThe1Campaign .rdt_TableRow .rdt_TableCell:nth-child(2) {
  position: sticky;
  background-color:#fff ;
  left: 150px;
  z-index: 1;
 }
 .viewTableThe1Campaign .rdt_TableRow:hover .rdt_TableCell:nth-child(2) {
  position: sticky;
  background-color: #e2effb !important;
  transition-duration: 0.15s;
  transition-property: background-color;
  border-bottom-color: #e2effb !important;
  left: 150px;
  z-index: 1;
 }

 .viewTableBzbCampaign .rdt_TableHeadRow .rdt_TableCol:nth-child(1) {
  position: sticky;
  background-color:#e2e1e1 ;
  left: 0;
  z-index: 1;
 }
 .viewTableBzbCampaign .rdt_TableRow .rdt_TableCell:nth-child(1) {
  position: sticky;
  background-color:#fff ;
  left: 0;
  z-index: 1;
 }
 .viewTableBzbCampaign .rdt_TableRow:hover .rdt_TableCell:nth-child(1) {
  position: sticky;
  background-color: #e2effb !important;
  transition-duration: 0.15s;
  transition-property: background-color;
  border-bottom-color: #e2effb !important;
  left: 0;
  z-index: 1;
 }
 .viewTableBzbCampaign .rdt_TableHeadRow .rdt_TableCol:nth-child(2) {
  position: sticky;
  background-color:#e2e1e1 ;
  left: 200px;
  z-index: 1;
 }
 .viewTableBzbCampaign .rdt_TableRow .rdt_TableCell:nth-child(2) {
  position: sticky;
  background-color:#fff ;
  left: 200px;
  z-index: 1;
 }
 .viewTableBzbCampaign .rdt_TableRow:hover .rdt_TableCell:nth-child(2) {
  position: sticky;
  background-color: #e2effb !important;
  transition-duration: 0.15s;
  transition-property: background-color;
  border-bottom-color: #e2effb !important;
  left: 200px;
  z-index: 1;
 }

 .viewTableTopservCampaign .rdt_TableHeadRow .rdt_TableCol:nth-child(1) {
  position: sticky;
  background-color:#e2e1e1 ;
  left: 0;
  z-index: 1;
 }
 .viewTableTopservCampaign .rdt_TableRow .rdt_TableCell:nth-child(1) {
  position: sticky;
  background-color:#fff ;
  left: 0;
  z-index: 1;
 }
 .viewTableTopservCampaign .rdt_TableRow:hover .rdt_TableCell:nth-child(1) {
  position: sticky;
  background-color: #e2effb !important;
  transition-duration: 0.15s;
  transition-property: background-color;
  border-bottom-color: #e2effb !important;
  left: 0;
  z-index: 1;
 }
 .viewTableTopservCampaign .rdt_TableHeadRow .rdt_TableCol:nth-child(2) {
  position: sticky;
  background-color:#e2e1e1 ;
  left: 250px;
  z-index: 1;
 }
 .viewTableTopservCampaign .rdt_TableRow .rdt_TableCell:nth-child(2) {
  position: sticky;
  background-color:#fff ;
  left: 250px;
  z-index: 1;
 }
 .viewTableTopservCampaign .rdt_TableRow:hover .rdt_TableCell:nth-child(2) {
  position: sticky;
  background-color: #e2effb !important;
  transition-duration: 0.15s;
  transition-property: background-color;
  border-bottom-color: #e2effb !important;
  left: 250px;
  z-index: 1;
 }

 .viewTableTAR .rdt_TableHeadRow .rdt_TableCol:nth-child(1) {
  position: sticky;
  background-color:#e2e1e1 ;
  left: 0;
  z-index: 1;
 }
 .viewTableTAR .rdt_TableRow .rdt_TableCell:nth-child(1) {
  position: sticky;
  background-color:#fff ;
  left: 0;
  z-index: 1;
 }
 .viewTableTAR .rdt_TableRow:hover .rdt_TableCell:nth-child(1) {
  position: sticky;
  background-color: #e2effb !important;
  transition-duration: 0.15s;
  transition-property: background-color;
  border-bottom-color: #e2effb !important;
  left: 0;
  z-index: 1;
 }
 .viewTableTAR .rdt_TableHeadRow .rdt_TableCol:nth-child(2) {
  position: sticky;
  background-color:#e2e1e1 ;
  left: 120px;
  z-index: 1;
 }
 .viewTableTAR .rdt_TableRow .rdt_TableCell:nth-child(2) {
  position: sticky;
  background-color:#fff ;
  left: 120px;
  z-index: 1;
 }
 .viewTableTAR .rdt_TableRow:hover .rdt_TableCell:nth-child(2) {
  position: sticky;
  background-color: #e2effb !important;
  transition-duration: 0.15s;
  transition-property: background-color;
  border-bottom-color: #e2effb !important;
  left: 120px;
  z-index: 1;
 }
 .viewTableTAR .rdt_TableHeadRow .rdt_TableCol:nth-child(3) {
  position: sticky;
  background-color:#e2e1e1 ;
  left: 370px;
  z-index: 1;
 }
 .viewTableTAR .rdt_TableRow .rdt_TableCell:nth-child(3) {
  position: sticky;
  background-color:#fff ;
  left: 370px;
  z-index: 1;
 }
 .viewTableTAR .rdt_TableRow:hover .rdt_TableCell:nth-child(3) {
  position: sticky;
  background-color: #e2effb !important;
  transition-duration: 0.15s;
  transition-property: background-color;
  border-bottom-color: #e2effb !important;
  left: 370px;
  z-index: 1;
 }


 .viewTableAudience .rdt_TableHeadRow .rdt_TableCol:nth-child(1) {
  position: sticky;
  background-color:#e2e1e1 ;
  left: 0;
  z-index: 1;
 }
 .viewTableAudience .rdt_TableRow .rdt_TableCell:nth-child(1) {
  position: sticky;
  background-color:#fff ;
  left: 0;
  z-index: 1;
 }
 .viewTableAudience .rdt_TableRow:hover .rdt_TableCell:nth-child(1) {
  position: sticky;
  background-color: #e2effb !important;
  transition-duration: 0.15s;
  transition-property: background-color;
  border-bottom-color: #e2effb !important;
  left: 0;
  z-index: 1;
 }
 .viewTableAudience .rdt_TableHeadRow .rdt_TableCol:nth-child(2) {
  position: sticky;
  background-color:#e2e1e1 ;
  left: 350px;
  z-index: 1;
 }
 .viewTableAudience .rdt_TableRow .rdt_TableCell:nth-child(2) {
  position: sticky;
  background-color:#fff ;
  left: 350px;
  z-index: 1;
 }
 .viewTableAudience .rdt_TableRow:hover .rdt_TableCell:nth-child(2) {
  position: sticky;
  background-color: #e2effb !important;
  transition-duration: 0.15s;
  transition-property: background-color;
  border-bottom-color: #e2effb !important;
  left: 350px;
  z-index: 1;
 }
 
 .mt-01 {
  margin-top: 0.1rem !important;
 }
 .mt-04 {
  margin-top: 0.4rem !important;
 }

.contentCenterV {
  align-content: center;
}

.custom-modal-campaign {
  max-width: 95vw !important; /* Increase the modal width */
  width: 95vw !important; /* Optional: Ensures the width is applied */
}

.custom-tooltip{
  position: absolute;
  top: 20%;
}

.custom-tooltip > .tooltip {
  z-index: 0 !important;
}

.custom-cell {
  border: 1px solid #b3b6b6;
  border-top: none;
  padding-inline: 0.5rem; /* For horizontal padding */
  padding-block: 0.3rem; /* For vertical padding */
  text-align: center;
  height: 41px;
}

.custom-header {
  background: #dee2e3;
  border-right: 1px solid #b3b6b6;
  padding-inline: 0.5rem; /* Horizontal padding */
  padding-block: 0.3rem; /* Vertical padding */
  font-size: 16px;
  font-weight: 500;
  height: 41px;
}

.custom-header-right {
  background: #dee2e3;
  padding-inline: 0.5rem; /* Horizontal padding */
  padding-block: 0.3rem; /* Vertical padding */
  font-size: 16px;
  font-weight: 500;
  height: 41px;
  border-top-right-radius: 6px;
}

.custom-header-left {
  background: #dee2e3;
  border-right: 1px solid #b3b6b6;
  padding-inline: 0.5rem; /* Horizontal padding */
  padding-block: 0.3rem; /* Vertical padding */
  font-size: 16px;
  font-weight: 500;
  height: 41px;
  border-top-left-radius: 6px;
}



//  .tableInquery .rdt_TableBody{
  
//   max-height: 70vh;
//   overflow: hidden;
//   overflow-y: scroll;
  
//   &::-webkit-scrollbar {
//     height: 6px;
//   }

//   &::-webkit-scrollbar-track {
//     -webkit-box-shadow: inset 10px 10px 10px rgba(0, 0, 0, 0);
//   }

//   &::-webkit-scrollbar-thumb {
//     background-color: #4e4e4e;
//     border-radius: 100px;
//     background-clip: padding-box;
//     border: 2px solid rgba(0, 0, 0, 0);
//     // display: none;
//   }

//   &::-webkit-scrollbar-thumb:hover {
//     background-color: #4e4e4e;
//     background-clip: padding-box;
//     border: 2px solid rgba(0, 0, 0, 0);
//     // display: block;
//   }

//   &::-webkit-scrollbar:vertical {
//     display: none;
//   }
//  }
.tableInqueryParameter{
  overflow-y: auto !important;
    height: fit-content;
    max-height: 546px;
    // scrollbar-width: none;
  .rdt_Table {
    // overflow-y: scroll;
    .rdt_TableHead {
      position: sticky;
      top: 0;
      z-index: 9;
    }
  }
}
.parameterDtlCard{
  min-height: 540px;
  height: auto;
}

.tableInquery{
  overflow-y: auto !important;
    max-height: 70vh;
    // scrollbar-width: none;
  .rdt_Table {
    // overflow-y: scroll;
    .rdt_TableHead {
      position: sticky;
      top: 0;
      z-index: 9;
    }
  }
} 
.tox-statusbar__help-text {
  display: none;
}
 
.thumbnailFileUrl .modal-content {
  width: 450px;
}

.imageFileUrl .modal-content {
  width: 450px;
}


// activity management
.header-activity-card{
  font-size: 18px;
  font-weight: 500;
}

.viewTableBlacklist .rdt_TableHeadRow .rdt_TableCol:nth-child(1) {
  position: sticky;
  background-color:#e2e1e1 ;
  left: 0;
  z-index: 2;
 }
 .viewTableBlacklist .rdt_TableRow .rdt_TableCell:nth-child(1) {
  position: sticky;
  background-color:#fff ;
  left: 0;
  z-index: 1;
 }
 .viewTableBlacklist .rdt_TableRow:hover .rdt_TableCell:nth-child(1) {
  position: sticky;
  background-color: #e2effb !important;
  transition-duration: 0.15s;
  transition-property: background-color;
  border-bottom-color: #e2effb !important;
  left: 0;
  z-index: 1;
 }
 .viewTableBlacklist .rdt_TableHeadRow .rdt_TableCol:nth-child(2) {
  position: sticky;
  background-color:#e2e1e1 ;
  left: 200px;
  z-index: 2;
 }
 .viewTableBlacklist .rdt_TableRow .rdt_TableCell:nth-child(2) {
  position: sticky;
  background-color:#fff ;
  left: 200px;
  z-index: 1;
 }
 .viewTableBlacklist .rdt_TableRow:hover .rdt_TableCell:nth-child(2) {
  position: sticky;
  background-color: #e2effb !important;
  transition-duration: 0.15s;
  transition-property: background-color;
  border-bottom-color: #e2effb !important;
  left: 200px;
  z-index: 1;
 }

.hover-btn .btn:hover {
  background-color: #347BBD !important;
  border-color:  #347BBD !important;
  // opacity: 0.8;
}
.btn-action:hover {
  background-color: #347BBD !important;
  border-color:  #347BBD !important;
}
.hover-btn .btn {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  min-width: 110px !important;
  min-height: 40px !important;
  overflow: hidden;

  .iconify {
    font-size: 1.15em;
    margin-bottom: -0.3em;
    margin-top: -0.5em;
  }
}

.hovering .btn:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}

.icon-circle {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%; 
  background-color: #3D93E2;
  color: white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); 
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.icon-circle:hover {
  background-color: #0056b3;
}

.plus-btn {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  // width: 52.19px !important;
  min-height: 40px !important;
  padding: 0px;
  overflow: hidden;

  .iconify {
    font-size: 1.15em;
    margin-bottom: -0.3em;
    margin-top: -0.5em;
  }
}

.viewTableSurvey .rdt_TableHeadRow .rdt_TableCol:nth-child(1) {
  position: sticky;
  background-color:#e2e1e1 ;
  left: 0;
  z-index: 2;
 }
 .viewTableSurvey .rdt_TableRow .rdt_TableCell:nth-child(1) {
  position: sticky;
  background-color:#fff ;
  left: 0;
  z-index: 1;
 }
 .viewTableSurvey .rdt_TableRow:hover .rdt_TableCell:nth-child(1) {
  position: sticky;
  background-color: #e2effb !important;
  transition-duration: 0.15s;
  transition-property: background-color;
  border-bottom-color: #e2effb !important;
  left: 0;
  z-index: 1;
 }
 .viewTableSurvey .rdt_TableHeadRow .rdt_TableCol:nth-child(2) {
  position: sticky;
  background-color:#e2e1e1 ;
  left: 400px;
  z-index: 2;
 }
 .viewTableSurvey .rdt_TableRow .rdt_TableCell:nth-child(2) {
  position: sticky;
  background-color:#fff ;
  left: 400px;
  z-index: 1;
 }
 .viewTableSurvey .rdt_TableRow:hover .rdt_TableCell:nth-child(2) {
  position: sticky;
  background-color: #e2effb !important;
  transition-duration: 0.15s;
  transition-property: background-color;
  border-bottom-color: #e2effb !important;
  left: 400px;
  z-index: 1;
 }

 .emoji-modal-content {
  width: fit-content !important;
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
  overflow: hidden !important;
}

.emoji-modal-content .emoji-picker-react {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  overflow-y: hidden; /* Hides vertical scrollbar */
}

.emoji-modal-content .emoji-picker-react::-webkit-scrollbar {
  display: none; /* For WebKit browsers (Chrome, Safari) */
}

.emoji-picker-react {
  max-height: unset !important; /* Prevent internal picker from setting its height */
}

/* Default styles for the container */
.survey-checkbox-container {
  display: flex;
  align-items: center;
  gap: 2rem; 
  flex-wrap: nowrap; 
  white-space: nowrap; 
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .survey-checkbox-container {
    flex-wrap: wrap; 
    gap: 0.5rem;
  }

  .survey-checkbox-container .form-check {
    flex: 1 1 auto;
    min-width: 100%; 
  }
}

.answer-cell-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%; 
}

.answer-input {
  flex: 1;
  display: flex;
  align-items: center; 
}

.answer-text {
  font-size: 10px; 
  color: #757575; 
  line-height: 0; 
}

.copy-icon{
  color: #1b91ec !important;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.video-container {
  position: relative;
  width: 100%; /* Full width of the container */
  height: auto;
  max-width: 100%; /* Prevent overflow */
  display: flex;
  justify-content: center; /* Center the video horizontally */
  align-items: center; /* Center the video vertically if needed */
}

.video-responsive {
  width: 100%; /* Makes the video responsive to its container */
  max-width: 100%; /* Prevents the video from exceeding its container width */
  height: auto; /* Maintains the aspect ratio */
  max-height: calc(100vh - 20px); /* Keeps the video within the viewport height */
  object-fit: contain; /* Ensures the video scales correctly without clipping */
}