.column-5 {
  width: 5%;
}
.column-10 {
  width: 10%;
}
.column-20 {
  width: 20%;
}
.column-30 {
  width: 30%;
}
.column-100 {
  width: 100%;
}
.column-120px {
  width: 120px;
}
.column-180px {
  width: 180px;
}
.column-260px {
  width: 260px;
}
.column-500px {
  width: 500px;
}
.padding-left-16px {
  padding-left: 16px;
}

.padding-right-30px {
  padding-right: 30px;
}
.padding-bottom-15px {
  padding-bottom: 15px;
}
.padding-left-55px {
  padding-left: 55px;
}
.text-rigth {
  text-align: right;
}

.text-center {
  text-align: center;
}

.border-grey{
  border: solid $medium-dark-gray 1px;
  // border-radius: .25rem !important;
}


.custom-table tr{
  border: solid $medium-dark-gray 1px;
}

.custom-table td{
  border-left: solid $medium-dark-gray 1px;
  border-right: solid $medium-dark-gray 1px;
  border-bottom: solid $medium-dark-gray 1px;
}

.custom-table th {
  background-color: #e2e1e1;
  color: black !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  border: solid $medium-dark-gray 1px;
  border-bottom: none;
}

.custom-table th.center{
  text-align: center !important;
  text-transform: unset;
  padding: 0.75rem !important;
}

.custom-table tbody tr:hover {
  background-color: #e2effb; /* Light AliceBlue color */
}



.height-240px {
  height: 240px;
}

.min-height-150px {
  min-height: 150px;
}
.min-height-300px {
  min-height: 40vh;
}
.sc-dmXWDj.h-bg-lightprimary {
  .rdt_TableHeadRow {
    background-color: #c0e4ff;
  }
}
